export default {
    empty: "Nada a aprovar",
    saved: "Aprovações enviadas",
    review: "",
    message: {
        message: ""
    },
    card: {
        name: {
            label: "Nome"
        },
        date: {
            label: "Data"
        },
        details: {
            label: "Detalhes"
        },
        price: {
            label: "Preço"
        },
        manager: {
            label: "Gerente"
        },
        requested: {
            label: "Solicitado por"
        },
        history: {
            cta: {
                load: "Aprovações prévias",
                loading: "Carregando",
                show: "Aprovações prévias",
                hide: "Aprovações prévias"
            },
            approvals: {
                empty: "Nenhuma aprovação prévia",
                item: {
                    status: {
                        approver: "por {approver}",
                        system: "Central de Ajuda",
                        approved: "Aprovado",
                        rejected: "Não aprovado",
                        unrequired: "Aprovação não necessária",
                        reason: "Razão",
                        transferred: "Transferido"
                    }
                }
            }
        },
        reason: {
            label: "Razão",
            placeholder: "Preencha o motivo"
        },
        cta: {
            approve: {
                cta: "Aprovar",
                processing: "Salvando"
            },
            disapprove: {
                cta: "Não aprovar",
                processing: "Salvando"
            }
        },
        saved: "Aprovações enviadas"
    }
}