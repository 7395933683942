export default {
    disclaimer: {
        label_default: "I acknowledge and agree that the items I am ordering / receiving are Company property to be used for Company work related activities, and as such, the Company reserves the right to request return of the property upon my exit from the Company (or sooner, if required by the Company).",
        label_type_1: "I confirm that the self-assessment for homeworking was completed accurately and in good faith, and any equipment or furniture provided from a Company location or ordered through Gear will be used by me for the purpose of my work with the Company. I acknowledge and agree that the items I am ordering / receiving are Company property to be used for Company work related activities, and as such, the Company reserves the right to request return of the property upon my exit from the Company (or sooner, if required by the Company).",
        errors: {
            required: "Please accept terms of use"
        }
    },
    cost_center: {
        label: "Cost Center Code",
        placeholder: "Cost center code",
        cost_center_not_found: "Costs centers not present can take up to 24 hours to appear.  Contact the  Global Service Desk if you do not see the appropriate cost center.",
        errors: {
            regex: "Invalid cost center code"
        }
    },
    company_code: {
        label: "Company Code"
    },
    cost_center_name: {
        label: "Cost Center Name"
    },
    order_note: {
        label: "Order Note",
        placeholder: "Optional note to manager for approval",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Notify me with updates on this order"
    }
}